import { facottryXHR } from "./networkUtils";

// import crypto from "crypto-browserify"

// const crypto = require("crypto-browserify");


/**
 * The function `getServerConfig` sends a POST request to a server with specified payload data and
 * returns a response object through a callback function.
 * @param postUrl - The `postUrl` parameter is the URL to which the POST request will be sent to
 * retrieve server configuration data.
 * @param [payload] - The `payload` parameter in the `getServerConfig` function is an object that
 * contains information about the project and filtering criteria. It includes the following properties:
 * @param callback - The `callback` parameter in the `getServerConfig` function is a function that will
 * be called with the response object after the server configuration data is retrieved and processed.
 * This function allows you to handle the response data in your code, such as updating the UI or
 * performing additional logic based on the server
 */
function getServerConfig(postUrl, payload = {}, callback) {
  let projectID = payload.projectID;

  let filter = {
    ...payload.filter
  };

  let postParams = {
    "projectID": projectID,
    "filter": filter
  };

  function privateCallback(response) { 
    if ((response.code == 200  ||response.code == "FOUND")  && response.data) {
      callback(response.data);
    } else {
      callback(response);
    }
  }

  facottryXHR(postUrl, postParams, function (response) {
    console.log("facottryXHR response", response);
    let responseObj = {};
    if (typeof response == "string") {
      let responseText = response;
      try {
        responseObj = JSON.parse(responseText);
      } catch (error) {
        responseObj = {
          success: false,
          message: error.message,
          rawError: responseText
        };
      }
      console.log("facottryXHR responseObj", responseObj);
    } else if (typeof response == "object") {
      responseObj = response
    }
    privateCallback(responseObj);
  });
}

function isValidClientConfig(clientConfig) {
  if (!clientConfig) {
    return false;
  }
  if (!clientConfig.projectID || clientConfig.projectID === "" || typeof clientConfig.projectID !== "string") {
    return false;
  }
  if (!clientConfig.filter || Object.keys(clientConfig.filter).length === 0) {
    return false;
  }
  return true;

}


function generateScaleHash() {
  // const date = new Date();
  // const currentHour = date.getHours();
  // const currentMinute = date.getMinutes();
  // const randomizer = Math.floor(Math.random() * 10);
  // const permanentSalt = "5adf9a7c3be84f966c00dc5c33a4a115f311eb1a962e540c0beccdc5d6d171d4";

  // const temporarySalt = `${currentHour}${currentMinute}`;
  // const dataToHash = `${permanentSalt}${temporarySalt}${randomizer}`;

  // const generatedHash = crypto
  //   .createHash("sha256")
  //   .update(dataToHash)
  //   .digest("hex");

  // return generatedHash;
};


export {
  isValidClientConfig,
  getServerConfig,
  generateScaleHash
}