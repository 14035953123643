import { generateScaleHash } from "./facottryUtils.js";


/**
 * The function `facottryXHR` sends a POST request with JSON data to a specified URL and calls a
 * callback function with the response text.
 * @param postUrl - The `postUrl` parameter in the `facottryXHR` function is the URL where the POST
 * request will be sent to. This should be a valid endpoint that can handle the POST request and
 * process the data being sent.
 * @param data - The `data` parameter in the `facottryXHR` function is an object with two properties:
 * @param callback - The `callback` parameter in the `facottryXHR` function is a function that will be
 * called once the XMLHttpRequest operation is completed. It typically takes the response text from the
 * server as an argument and can be used to handle the response data in the calling code.
 */
function facottryXHR(postUrl, payload = {}, callback) {
  // WARNING: For POST requests, body is set to null by browsers.
  var data = JSON.stringify(payload);

  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  // let scaleHash = generateScaleHash(data);
  // xhr.setRequestHeader("x-client-hash", scaleHash);

  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      console.log("this.responseText", this.responseText);
      let responseObj = {};

      try {
        responseObj = JSON.parse(this.responseText);
        callback(responseObj);
      } catch (error) {
        callback({
          success: false,
          message: error.message,
          rawError: this.responseText
        })
      }
    }

  });

  xhr.open("POST", postUrl);
  xhr.setRequestHeader("accept", "application/json, text/plain, */*");
  xhr.setRequestHeader("accept-language", "en-US,en;q=0.9");
  xhr.setRequestHeader("content-type", "application/json");

  xhr.send(data);
}


export {
  facottryXHR
}