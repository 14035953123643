import { getServerConfig, isValidClientConfig } from "./facottryUtils";
import { userCountry } from "./country";
import { baseFacottryUrl } from "./facottryEnum";


/* The `FacottryClass` class in JavaScript handles retrieving and merging configuration settings from
local and server sources. */
class FacottryClass {
    constructor() {
        this.facottryClientConfig = {};
        this.facottryGlobalConfig = {};
        this.baseFacottryUrl = baseFacottryUrl;
        //Do not write anything in this function
    }
    /**
     * The function `saveGlobalConfig` checks if a global configuration object exists in the window and
     * assigns it to a local variable if it does.
     */

    saveGlobalConfig() {
        if (typeof window.facottryGlobalConfig === 'object') {
            this.facottryGlobalConfig = window.facottryGlobalConfig;
        }
    }




    /**
     * The function `overRideCountry` overrides the country in the `facottryClientConfig` object with the
     * `userCountry.code` if it exists.
     * @param facottryClientConfig - facottryClientConfig is an object that likely contains configuration
     * settings for a factory or production facility. The function overRideCountry takes this object as a
     * parameter and updates the country property within it based on the user's country code if it exists.
     * If the userCountry object has a code property,
     * @returns The function `overRideCountry` is returning the `facottryClientConfig` object after
     * potentially updating its `country` property with the `userCountry.code` value.
     */
    overRideCountry(facottryClientConfig = {}) {
        facottryClientConfig.filter = facottryClientConfig.filter || {};
        if (userCountry.code) {
            facottryClientConfig.filter.COUNTRY = userCountry.code;
        }
        return facottryClientConfig;
    }

    /**
     * The `getConfig` function in JavaScript retrieves configuration settings from both local and server
     * sources and returns a Promise with the merged configuration.
     * @param facottryClientConfig - It looks like there is a typo in the parameter name
     * "facottryClientConfig". It should probably be "factoryLocalConfig".
     * @returns A Promise object is being returned from the `getConfig` function.
     */
    __getConfigFromServer(facottryMergedConfig) {
        console.log('getConfig Step 2', facottryMergedConfig);

        var clientPromise = new Promise((resolve, reject) => {

            if (!isValidClientConfig(facottryMergedConfig)) {
                reject({
                    success: false,
                    message: 'Invalid client config',
                    errorCode: 'INVALID_CLIENT_CONFIG'
                });
                return;
            }

            getServerConfig(this.baseFacottryUrl, facottryMergedConfig, function (__facottryServerConfig) {
                console.log('facottryServerConfig :: ', __facottryServerConfig);
                if (__facottryServerConfig.success === false) {
                    reject({
                        success: false,
                        message: 'Invalid server config',
                        errorCode: 'INVALID_SERVER_CONFIG'
                    });
                    return
                }
                __facottryServerConfig.mappings = __facottryServerConfig.mappings || {};

                let facottryServerConfigForClient = {};
                for (let key in __facottryServerConfig.mappings) {
                    facottryServerConfigForClient[key] = __facottryServerConfig.mappings[key];
                }

                if (typeof facottryMergedConfig.onFacottryLoaded === 'function') {
                    facottryMergedConfig.onFacottryLoaded(facottryServerConfigForClient)
                }
                console.log('facottryServerConfigForClient :: ', facottryServerConfigForClient);
                resolve(facottryServerConfigForClient);
            });
        });
        return clientPromise;

    }


    /**
    * The `getConfigFromServer` function logs a message, overrides a global configuration with a local
    * one, and then calls another function with the global configuration.
    */
    getConfigFromServer(facottryClientConfig) {
        console.log('getConfigFromServer', facottryClientConfig);
        this.facottryClientConfig = facottryClientConfig;
        if (this.facottryGlobalConfig.countryDetection == "AUTO") {
            this.facottryClientConfig = this.overRideCountry(facottryClientConfig);
        }

        let facottryMergedConfig = { ...this.facottryGlobalConfig, ...this.facottryClientConfig };
        return this.__getConfigFromServer(facottryMergedConfig);
    }

    init(facottryClientConfig) {
        this.saveGlobalConfig();
        return this.getConfigFromServer(facottryClientConfig);
    }


}


export default FacottryClass

/* `window.FACOTTRY = new FacottryClass();` is creating a new instance of the `FacottryClass` class and
assigning it to the `FACOTTRY` property of the `window` object. This allows the `FacottryClass`
instance to be accessed globally through the `FACOTTRY` property on the `window` object. */
window.FACOTTRY = new FacottryClass();